import Router from 'vue-router';
import Vue from 'vue';
import { auth } from '@/auth';
import { EventBus } from '@/event-bus';

EventBus.$on('auth', () => {
	if (!routingHandler.router) return;
	const currentQuery = routingHandler.router.currentRoute.query;
	const _isAuthenticated = auth.isAuthenticated;

	if (!_isAuthenticated) {
		/*if (
			!(
				(router as any).history.pending &&
				(router as any).history.pending.meta.anonOnly
			)
		)*/

		if (
			(routingHandler.router.currentRoute.params.vaultId || '') == '' ||
			(routingHandler.router.currentRoute.params.userHash || '') == ''
		) {
			routingHandler.router.push({
				name: 'landing',
				query: currentQuery
			});
		} else {
			let loginPath = `/${routingHandler.router.currentRoute.params
				.vaultId || ''}/${routingHandler.router.currentRoute.params
				.userHash || ''}/login`;

			if (routingHandler.router.currentRoute.path !== loginPath) {
				routingHandler.router.push(loginPath);
			} else {
				location.reload();
			}
		}
	} else {
		let newPath = currentQuery.hasOwnProperty('redirect')
			? `/${routingHandler.router.currentRoute.params.vaultId ||
					''}/${routingHandler.router.currentRoute.params.userHash ||
					''}/${(currentQuery.redirect as string)
					.replace(
						routingHandler.router.currentRoute.params.vaultId,
						''
					)
					.replace(
						routingHandler.router.currentRoute.params.userHash,
						''
					)}`
			: `/${routingHandler.router.currentRoute.params.vaultId ||
					''}/${routingHandler.router.currentRoute.params.userHash ||
					''}/home`;
		while (newPath.includes('//')) newPath = newPath.split('//').join('/');

		delete currentQuery.redirect;
		let newQueryString = '';
		for (let k in currentQuery) {
			newQueryString += `&${k}=${currentQuery[k]}`;
		}
		if (newQueryString.length > 0) {
			newQueryString = `?${newQueryString.substring(1)}`;
		}
		routingHandler.router.push(newPath + newQueryString);

		const checkTimeout = () => {
			if (!auth.isAuthenticated && routingHandler.router) {
				auth.logOut();
				document.location.reload();
				routingHandler.router.push({
					name: 'landing',
					query: currentQuery
				});
			} else setTimeout(checkTimeout, 900000);
		};
		checkTimeout();
	}
	EventBus.$emit('user');
});

Vue.use(Router);

declare interface IRoutingHandler {
	router: Router | undefined;
	initialize(basePath: string): void;
}
const routingHandler: IRoutingHandler = {
	router: undefined,
	initialize: function(basePath: string) {
		this.router = new Router({
			mode: 'history',
			base: basePath,
			routes: [
				// route level code-splitting
				// this generates a separate chunk ([route].[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				{
					path: '/',
					name: 'landing',
					component: () =>
						import(
							/* webpackChunkName: "landing" */ './views/Landing.vue'
						),
					meta: { anonOnly: true }
				},
				{
					path: '/:vaultId/:userHash/login',
					name: 'login',
					component: () =>
						import(
							/* webpackChunkName: "logIn" */ './views/LogIn.vue'
						),
					meta: { anonOnly: true },
					props: true
				},
				{
					path: '/:vaultId/:userHash/kiosk',
					name: 'kiosk',
					component: () =>
						import(
							/* webpackChunkName: "kiosk" */ './views/kiosk/Kiosk.vue'
						),
					meta: { authOnly: true },
					props: true
				},
				{
					path: '/:vaultId/:userHash/kiosk/sign-documents',
					name: 'sign-documents',
					component: () =>
						import(
							/* webpackChunkName: "sign-documents" */ './views/kiosk/Sign-Documents.vue'
						),
					meta: { authOnly: true },
					props: true
				},
				{
					path: '/:vaultId/:userHash/kiosk/sign-in',
					name: 'sign-in',
					component: () =>
						import(
							/* webpackChunkName: "sign-in" */ './views/kiosk/Sign-In.vue'
						),
					meta: { anonOnly: true },
					props: true
				},
				{
					path: '/:vaultId/:userHash/kiosk/sign-out',
					name: 'sign-out',
					component: () =>
						import(
							/* webpackChunkName: "sign-out" */ './views/kiosk/Sign-Out.vue'
						),
					props: true
				},
				{
					path: '/:vaultId/:userHash/home*',
					name: 'home',
					component: () =>
						import(
							/* webpackChunkName: "home" */ './views/Home.vue'
						),
					meta: { authOnly: true },
					props: true
				},
				{
					path: '/:vaultId/:userHash/lo',
					name: 'lo',
					component: () =>
						import(
							/* webpackChunkName: "home" */ './views/Logout.vue'
						),
					meta: { authOnly: true },
					props: true
				},
				// {
				// 	path: '/:vaultId/:userHash/identification-card',
				// 	name: 'identification-card',
				// 	component: () =>
				// 		import(
				// 			/* webpackChunkName: "home" */ './views/Identification-Card.vue'
				// 		),
				// 	meta: { authOnly: true },
				// 	props: true
				// },
				// {
				// 	path: '/:vaultId/:userHash/identification-card2',
				// 	name: 'identification-card2',
				// 	component: () =>
				// 		import(
				// 			/* webpackChunkName: "home" */ './views/Landing.vue'
				// 		),
				// 	meta: { authOnly: true },
				// 	props: true
				// },
				{
					path: '*',
					name: '404',
					component: () =>
						import(
							/* webpackChunkName: "notFound" */ './views/NotFound.vue'
						)
				}
			]
		});

		// Route case-sensitivity hotfix
		if (this.router.mode === 'history') {
			this.router['history'].getCurrentLocation = function() {
				let path = window.location.pathname;
				let base = this.router['history'].base;

				// Removes base from path (case-insensitive)
				if (
					base &&
					path.toLowerCase().indexOf(base.toLowerCase()) === 0
				) {
					path = path.slice(base.length);
				}

				return (
					(path || '/') +
					window.location.search +
					window.location.hash
				);
			};
		}

		this.router.beforeEach((to, from, next) => {
			auth.urlVault = to.params.vaultId || '';
			auth.urlUserHash = to.params.userHash || '';
			if (!from.name) auth.clearValidationCookie();
			// if ((to.params.identifier1 || '') !== '') {
			//	auth.clearAuthCookie();
			// }

			const kioskRoutes = ['kiosk', 'sign-in'];
			if (to.name && kioskRoutes.includes(to.name)) {
				next();
			} else if (to.matched.some(route => route.meta.authOnly)) {
				if (!auth.isAuthenticated) {
					
					let redirectPath = to.fullPath
						.replace(to.params.vaultId || '', '')
						.replace(to.params.userHash || '', '');
					while (redirectPath.includes('//'))
						redirectPath = redirectPath.split('//').join('/');
					next({
						name: 'login',
						params: to.params,
						query: {
							redirect: redirectPath
						}
					});
				} else {
					next();
				}
			} else if (to.matched.some(route => route.meta.anonOnly)) {
				let query = to.query;
				if (auth.isAuthenticated) {
					if ((to.query.identifier1 || '') !== '') {
						auth.clearAuthCookie();
						next();
					}

					let redirect: string = (query.redirect || '') as string;
					delete query.redirect;
					if (redirect !== '') {
						redirect = `/${to.params.vaultId || ''}/${to.params
							.userHash || ''}/${redirect}`;
						while (redirect.includes('//'))
							redirect = redirect.split('//').join('/');

						next({
							path: redirect,
							query: query
						});
					} else {
						next({
							name: 'home',
							params: to.params,
							query: query
						});
					}
				} else {
					auth.clearValidationCookie();
					next();
				}
			} else {
				next(); // make sure to always call next()!
			}
		});
	}
};

export default routingHandler;
